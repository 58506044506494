@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

$primary: #ff3330; /* MAIN COLOR */
$secondary: #ff3330; /* SECONDARY COLOR */
$gray: #666;
$blk: #333;
$wht: #f8f8f8;
$links: #444;
body {
  font-family: 'Exo', sans-serif;
  color: $blk;
  font-size: 1.5em;
}
hr.style11 {
	height: 6px;
	background: url('../img/hr.png') repeat-x 0 0;
    border: 0;
}
.navbar-toggle {
  margin-top: 17px;
}
.navbar {
  margin-bottom: 0;
}
.navbar-nav {
    margin: 0 -15px;}
.navbar, .navbar-header{
  height: 100px;
  // position: relative;

  .navbar-nav>li>a {
    margin-top: 25px;
    text-transform: uppercase;
    font-size: 1.2em;
    border:1px solid $wht;
    &:hover{
      border-bottom: 3px solid $secondary;
      color: $secondary;
      @media(max-width:767px){
        background-color: $wht;
        color: $blk;
        border-radius: 0;
        border:0px solid $wht;
      }
    }
    background: $wht;
    @media (max-width:767px){
      line-height: 20px;
      margin-top: 0;
    }
  }
  @media (max-width: 767px){
    height: 70px;
    .logo {
      max-height: 70px;
    }
  }
}
.navbar-brand {
    padding: 0px 15px;
}
.hero-slider {
  margin-top: 110px;
 #slider{
     border-radius:20px;
     box-shadow: 0px 0px 20px lighten($blk, 55%);
 }
}
.hero-about {
  padding: 60px 0;
  background-color:lighten($wht, 2%);
  color: $blk;
  @media(max-width: 767px){
    padding: 20px 0;
  }
  h2 {
    border-bottom: 3px solid $primary;
    padding-bottom: 10px ;
  }
  p {
    font-size: 1.2em;
    font-weight: 500;
    color: $blk;
    @media (max-width: 767px){
      margin: 20px 0;
      font-size: 1.2em;
    }
  }
  .cta-box{
    padding: 20px 0px;
  }
  .cta {
    padding: 20px 30px;
    background: $primary;
    font-size: .7em;
    color: $wht;
    transition: all 0.7s ease 0s;
    text-decoration: none;
    &:hover{
      background: $wht;
      color:$primary;
      box-shadow: 0px 0px 4px darken($wht, 25%);
    }
  }
}
.hero-services{
  background-color:darken($wht,1%);
  color: $gray;
  padding: 0px 0 50px 0 ;
  h1 {
    padding: 25px 0;
    color: $primary;
    border-bottom: 3px solid $primary;
  }
  .col-1, .col-2, .col-3{
    // padding:20px 0;
    margin-top:45px ;
    padding: 10px 18px;
    h2 {
      font-size: 1.5em;
      color: darken($primary, 5%);
    }
    p {
      font-size: 1.15em;
      padding: 0 20px;
      color: darken($blk, 25%);
    }

  }
  .col-1 {
      border-left: 1px solid $primary;
      border-right: 1px solid $primary;
    @media(max-width:991px){
      border-left: none;
      border-right: none;
      border-top: 1px solid $primary;
      border-bottom: 1px solid $primary;
    }
  }

}

.hero-img {
  // background-image: url('../img/hero-img.jpg');
  // background-repeat: no-repeat;
  // background-size: cover;
  padding-bottom: 50px;
  background-position:  bottom center;
  // height: 800px;

  h1 {
    color: $blk;
    margin-top: 70px;
    font-size: 2em;
    line-height: 1.5em;
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
  .cta-box{
    padding: 50px 0px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    // background: $primary;
  }
  .cta {
    padding: 20px 50px;
    background: $primary;
    font-size: 1.2em;
    color: $wht;
    transition: all 0.7s ease 0s;
    text-decoration: none;
    &:hover{
      background: $primary;
      color:$wht;
      box-shadow: 0px 0px 8px darken($wht, 25%);
    }
  }
}
.hero-contact {
  background-color:darken($wht,100%);
  padding: 0;
  color: $wht;
  .col-md-12 {
    padding: 20px 50px;
    @media(max-width: 767px){
      padding:40px 20px;
      color: lighten($wht, 0%);
    }
    ul {
      @media (max-width: 480px){
        padding-left: 10px;
      }
    }
    li {
      list-style: none;
      line-height: 26px;
    }
    a {
      text-decoration: none;
      color: lighten($wht, 0%);
      font-weight: 100;
    }
    p {
      font-size:1.2em;
      line-height:1.5em;
      color: lighten($wht, 0%);
    }
    .footer-banner {
      ul {
        padding-left: 0;
      }
    }
  }
}
footer {
  text-align: center;
  padding-top: 25px;
  background:darken($primary, 5%);
  color: lighten($wht, 40%);
}
